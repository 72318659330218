
import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

interface AuthContextType {
  token: string | null;
  roleId: string | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth deve ser usado dentro de um AuthProvider");
  }
  return context;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [token, setToken] = useState<string | null>(null);
  const [roleId, setRoleId] = useState<string | null>(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedRoleId = localStorage.getItem("roleId");

    if (storedToken) {
      setToken(storedToken);
    }
    if (storedRoleId) {
      setRoleId(storedRoleId);
    }
  }, []);

  const value = {
    token,
    roleId
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
