
import React from 'react';
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { MemoryRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./pages/AuthContext";

const Mapa = React.lazy(() => import('./pages/Mapa'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));

console.log(`The router.tsx loaded all imported files.`);
function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <MemoryRouter initialEntries={["/default"]}>
          <Routes>
            <Route path='/default' element={<Dashboard />} />
            <Route path='/mapa' element={<Mapa />} />
          </Routes>
        </MemoryRouter>
      </ThemeProvider>
    </AuthProvider> 
  );
}

export default App;
